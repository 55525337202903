import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "../src/util/rem";
import api from "../src/util/api";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

let app = createApp(App);
app.use(store).use(router).use(ElementPlus).mount("#app");
app.config.globalProperties.$api = api;