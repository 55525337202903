import axios from "axios";
import config from "../config/config";
let xhr = axios.create({
  baseURL: config.baseURL,
});
// 添加响应拦截器
xhr.interceptors.response.use(
  function (response) {
    console.log(response)
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    console.log(error, "请求错误");
    return Promise.reject(error);
  }
);
let api = {
  /**
   * 获取过程性评价报告
   * @param {*} ids
   */
  async AutoPdfInfo(ids, Ip) {
    return await xhr.post(Ip + "/api/Mu_Proce/AutoPdfInfo", ids);
  },
  //base64转图片
  async MsaveImageByBase64(data) {
    console.log(data)
    return await xhr.post(config.importurl + "/api/Pdf/MsaveImageByBase64", data);
  },
  /**
   * 获取家长评价
   * @param {*} data
   * @returns
   */
  async ParentAnswersPreview(data, Ip) {
    return await xhr.get(Ip + "/api/Mu_ParentAnswersInfo/ParentAnswersPreview", {
      params: data,
    });
  },

  /**
   * 获取家长评价(新)
   * @param {*} data
   * @returns
   */
  async FrontParentAnswerReport(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/EvalTerm/FrontParentAnswerReport", {
      params: data,
    });
  },


  // /学期评价
  async GetThreeReportingInterface(data, Ip) {
    return await xhr.get(Ip + "/api/Mu_AnswersInfo/GetThreeReportingInterface", {
      params: data,
    });
  },

  async GetBabyEvaluaTing(data, Ip) {
    return await xhr.get(Ip + "/api/Mu_BabyWeightStature/GetBabyEvaluaTing", {
      params: data,
    });
  },

  async GetMuAnswersByUserIdNew(data, Ip) {
    return await xhr.get(Ip + "/api/Mu_AnswersInfo/GetMuAnswersByUserIdNew", {
      params: data,
    });
  },

  ///api/Mu_Self_Defining/GetProceListSan
  async GetProceListSan(data, Ip) {
    return await xhr.get(Ip + "/api/Mu_Self_Defining/GetProceListSan", {
      params: data,
    });
  },
  // 成长足迹报告
  async StudentGrowReportInfo(data, Ip) {
    return await xhr.get(Ip + "/api/back/Bs_Growth/StudentGrowReportInfo", {
      params: data,
    });
  },

  // 成长足迹报告
  async GoTenantTempDetailOneInfoByCycleWriteCycle(data, Ip) {
    return await xhr.get(Ip + "/api/back/Bs_Growth/GoTenantTempDetailOneInfoByCycleWriteCycle", {
      params: data,
    });
  },
  // 成长足迹报告
  async GoTenantTempDetailOneInfoByCycleWrite(data, Ip) {
    return await xhr.get(Ip + "/api/back/Bs_Growth/GoTenantTempDetailOneInfoByCycleWrite", {
      params: data,
    });
  },
  // 成长足迹报告
  async GoTenantTempDetailOneInfoByImgTextRecord(data, Ip) {
    return await xhr.get(Ip + "/api/back/Bs_Growth/GoTenantTempDetailOneInfoByImgTextRecord", {
      params: data,
    });
  },
  async GetHe_DiseasePreventionDeatil(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/He_DiseasePreventionControlle/GetHe_DiseasePreventionDeatil", {
      params: data,
    });
  },
  async FavourtHe_DiseasePreventionDetail(data, Ip) {
    return await xhr.post(Ip + "/api/mgd/He_DiseasePreventionControlle/FavourtHe_DiseasePreventionDetail", data);
  },
  // 成长手册从
  async AutoBJQPdfInfo(data, Ip) {
    return await xhr.post(Ip + "/api/MuGrowthRecord/AutoBJQPdfInfo", data);
  },
  /**
   * 主题评价
   * @param {*} data 
   * @param {*} Ip 
   * @returns 
   */
  async GetBabyEval_ThemeDetail(data, Ip) {
    return await xhr.get(Ip + "/api/Mu_Theme/GetBabyEval_ThemeDetail", {
      params: data,
    });
  },
  /**
   * 主题评价(新)
   * @param {*} data 
   * @param {*} Ip 
   * @returns  /api/mgd/Eval_Theme/FronGetBabyEval_ThemeDetail
   */
  async FronGetBabyEval_ThemeDetail(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/Eval_Theme/FronGetBabyEval_ThemeDetail", {
      params: data,
    });
  },
  /**
   * 主题评价
   * @param {*} data 
   * @param {*} Ip 
   * @returns 
   */
  async GetCoverImgByThemeId(data, Ip) {
    return await xhr.get(Ip + "/api/back/Bs_Thtme/GetCoverImgByThemeId", {
      params: data,
    });
  },
  /**
   * 主题评价(新)
   * @param {*} data 
   * @param {*} Ip 
   * @returns  /api/mgd/Eval_Theme/AdminGetCoverImgByThemeId
   */
  async AdminGetCoverImgByThemeId(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/Eval_Theme/AdminGetCoverImgByThemeId", {
      params: data,
    });
  },
  /**
   * 主题评价 
   * @param {*} data 
   * @param {*} Ip 
   * @returns 
   */
  async GetThemeDesc(data, Ip) {
    return await xhr.get(Ip + "/api/back/Bs_Thtme/GetThemeDesc", {
      params: data,
    });
  },
  /**
   * 主题评价(新)
   * @param {*} data 
   * @param {*} Ip 
   * @returns /api/mgd/Eval_Theme/AdminGetThemeDesc
   */
  async AdminGetThemeDesc(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/Eval_Theme/AdminGetThemeDesc", {
      params: data,
    });
  },
  /**
   * 获取学期评价（新版本接口）
   * @param {*} data 
   * @param {*} Ip 
   * @returns /api/mgd/EvalTerm/FrontTermReportDetailInfo
   */
  async TermReportDetailInfo(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/EvalTerm/FrontTermReportDetailInfo", {
      params: data,
    });
  },

  // 过程
  async FrontAutoPdfInfo(data, Ip) {
    return await xhr.post(Ip + "/api/mgd/Eval_Proce/FrontAutoPdfInfo", data);
  },



  // 成长足迹报告(新)
  async StudentGrowReportInfoNew(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/Eval_Growth/StudentGrowReportInfo", {
      params: data,
    });
  },

  // 成长足迹报告（新）
  async GoTenantTempDetailOneInfoByCycleWriteCycleNew(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/Eval_Growth/GoTenantTempDetailOneInfoByCycleWriteCycle", {
      params: data,
    });
  },
  // 成长足迹报告（新）
  async GoTenantTempDetailOneInfoByCycleWriteNew(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/Eval_Growth/GoTenantTempDetailOneInfoByCycleWrite", {
      params: data,
    });
  },
  // 成长足迹报告（新）
  async GoTenantTempDetailOneInfoByImgTextRecordNew(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/Eval_Growth/GoTenantTempDetailOneInfoByImgTextRecord", {
      params: data,
    });
  },
  async GetStuOneDailyReportList(data, Ip) {
    return await xhr.get(Ip + "/api/mgd/OneDaily/GetStuOneDailyReportList", {
      params: data,
    });
  },
};



export default api;