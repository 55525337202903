import {
  createRouter,
  createWebHistory
} from "vue-router";
const routes = [{
  path: "/about",
  name: "About",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/About.vue"),
},
{
  // 过程报告
  path: "/processNew",
  name: "processNew",
  component: () => import("../views/processNew.vue"),
}, {
  // 过程报告
  path: "/process",
  name: "process",
  component: () => import("../views/process.vue"),
}, {
  // 过程报告
  path: "/process_f1",
  name: "process_f1",
  component: () => import("../views/process_f1.vue"),
},
{
  // 家长报告
  path: "/patriarch",
  name: "patriarch",
  component: () => import("../views/patriarch.vue"),
},
{
  // 家长报告
  path: "/patriarchNew",
  name: "patriarchNew",
  component: () => import("../views/patriarchNew.vue"),
},
{
  // 学期报告
  path: "/semester",
  name: "semester",
  component: () => import("../views/semester.vue"),
},
{
  // 学期新报告
  path: "/semesterNew",
  name: "semesterNew",
  component: () => import("../views/semesterNew.vue"),
},
{
  // 成长足迹
  path: "/growingFootprint",
  name: "growingFootprint",
  component: () => import("../views/growingFootprint.vue"),
},
{
  // 成长足迹
  path: "/growingFootprintNew",
  name: "growingFootprintNew",
  component: () => import("../views/growingFootprintNew.vue"),
},
{
  // 主题评价
  path: "/theme",
  name: "theme",
  component: () => import("../views/theme.vue"),
},
{
  // 主题评价(新)
  path: "/themeNew",
  name: "themeNew",
  component: () => import("../views/themeNew.vue"),
},

{
  // 文章评价
  path: "/Article",
  name: "Article",
  component: () => import("../views/Article.vue"),
},
{
  // 文章评价
  path: "/photo",
  name: "photo",
  component: () => import("../views/photo.vue"),
},
{

  path: "/reportNew",
  name: "reportNew",
  component: () => import("../views/reportNew.vue"),
},
{
  // 成长手册
  path: "/GrowthHandbookResult",
  name: "GrowthHandbookResult",
  component: () => import("../views/GrowthHandbookResult.vue"),
},
{

  path: "/oneDayReport",
  name: "oneDayReport",
  component: () => import("../views/oneDayReport.vue"),
},
{
  path: "/404",
  component: () => import( /* webpackChunkName: "about" */ "../views/404.vue"),
  name: "NoPage",
},
{
  path: "/:pathMatch(.*)",
  redirect: {
    path: "/404",
  },
},
];
console.log(routes)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;