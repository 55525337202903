<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  created() {
    this.init();
  },
  mounted() {
    let _this = this;
    window.onresize = () => {
      // 调用methods中的事件
      _this.init();
    };
  },
  methods: {
    init() {
      let htmlWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      // 得到html的Dom元素
      let htmlDom = document.getElementsByTagName("html")[0];
      if (htmlWidth >= 1920) {
        // 设置根元素字体大小
        htmlDom.style.fontSize = 192 + "px";
      } else {
        // 设置根元素字体大小
        htmlDom.style.fontSize = htmlWidth / 10 + "px";
      }
    },
  },
};
</script>
<style lang="less">
@import "../src/assets/font/iconfont.css";
* {
  padding: 0;
  // font-family: font;
  margin: 0;
  box-sizing: border-box;
  word-break: break-all;
}
@font-face {
  font-family: "font";
  src: url("./assets/font/font.ttf");
}
// @font-face {
//   font-family: "font"; //重命名字体名

//   src: url("../src/assets/font/QingSongShouXieTi2-2.ttf"); //引入字体
//   font-weight: normal;
//   font-style: normal;
// }
</style>
